@import "../../styles/variables.css";

.ModalRequestTour {
  z-index: var(--z-index-modal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffee;
  pointer-events: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility .35s, opacity .35s;
  will-change: visibility, opacity;
}

.show {
  composes: ModalRequestTour;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;

  .background {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes svg-animate-stroke {
  0% {
    stroke-dashoffset: 230;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.iconSuccessBig {
  margin: 1rem 0 5rem 0;
  stroke-dasharray: 230;
  stroke-dashoffset: 230;
  animation: svg-animate-stroke;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
}

.bgClose {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-dark);
  opacity: .3;
  cursor: default;
}

.background {
  display: flex;
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  opacity: 0;
  transform: translate(-50%, calc(-50% + 10px));
  transition: transform .35s var(--cubic-ease-out), opacity .35s;
  will-change: transform, opacity;
  transition-delay: .2s;
  width: calc(100% - (2 * var(--grid-gutter)));
  max-height: 90%;

  @media (--mq-sm) {
    width: calc(100% - (4 * var(--grid-column)));
    max-height: 90%;
  }

  @media (--mq-md) {
    width: 60%;
  }

  @media (--mq-lg) {
    width: 50%;
  }

  @media (--mq-xxl) {
    width: 30%;
  }
}

.content {
  position: relative;
  padding: var(--grid-gutter);
  overflow-y: auto;
  width: 100%;
  max-height: 100%;

  @media (--mq-md) {
    padding: var(--grid-gutter) var(--grid-column);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    &:first-of-type {
      margin-bottom: 1rem;
    }

    &:last-of-type {
      margin: 0;
    }
  }
}

.buttonOk {
  min-width: 12rem;
  justify-content: center;
  margin: 2rem 0;
}

.buttonClose {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 100%;

  svg {
    transform: rotate(45deg);
  }
}

.title {
  margin: 2rem 0 4rem 0;
}
