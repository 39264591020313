@import "../../styles/variables.css";

.InfoWindow {
  width: 224px;

  img {
    width: 100%;
    height: 112px;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 28px 12px 15px 12px;
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-lineheight-headers);

  > span {
    padding: .35rem 0;
    border-bottom: 1px solid var(--color-gray-light);
  }

  > button {
    justify-content: center;
    margin: 15px 0 0 0;
  }
}

.contentImage {
  composes: content;
  padding-top: 10px;
}

.status {
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: center;
  white-space: nowrap;

  > span {
    &[class^="dot"] {
      display: flex;
      width: 9px;
      min-width: 9px;
      height: 9px;
      min-height: 9px;
      border-radius: 100%;
      margin-right: .5rem;
      background-color: var(--color-black);

      &[class*="Under development"] {
        background-color: var(--color-yellow);
      }
      &[class*="In operation"] {
        background-color: var(--color-blue);
      }
      &[class*="Exited"] {
        background-color: var(--color-purple);
      }
    }
  }
}

.label {
  white-space: nowrap;
  color: var(--color-gray-dark);
}

[class="gm-style-iw gm-style-iw-c"] {
  padding: 0 !important;
  max-width: none !important;
  max-height: none !important;
}

[class="gm-style-iw-d"] {
  overflow: auto !important;
  max-height: none !important;
}

[class="gm-ui-hover-effect"] {
  opacity: 1 !important;
  top: .25rem !important;
  right: .25rem !important;
  background-color: var(--color-white) !important;
  border-radius: 100%;
}
