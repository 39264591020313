@import "../../styles/variables.css";

.PropertyTags {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-graphite);
  opacity: .9;
  width: 100%;
}

.PropertyTagsHorizontal {
  composes: PropertyTags;
  flex-direction: row;
  justify-content: flex-start;

  .platform {
    margin-bottom: 0 !important;

    &:not(:first-of-type) {
      margin: 0 0 0 1rem;
    }
  }
}

.platform {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }

  svg {
    width: 15px;
    margin-right: .5rem;
    transform: translateY(-2px);
  }

  span {
    font-family: var(--font-family-gotham);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--font-lineheight-headers);
  }
}
