fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

legend {
  padding: 0;
}

label {
  font-family: var(--font-family-gotham);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  margin-bottom: 5px;
  display: block;
}

input:not([type="submit"], [type="checkbox"], [type="radio"]), textarea {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: .75rem;
  background: transparent;
  border-radius: 10px;
  border: 1px solid currentColor;
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-lineheight-body);
  color: currentColor;
  appearance: none;

  &::placeholder {
    color: currentColor;
    opacity: .75;
  }
  &::selection {
    color: var(--color-white);
    background: var(--color-black);
  }

  &:focus {
    outline: none;

    &::placeholder {
      opacity: .5;
    }
  }

  + input:not([type="submit"], [type="checkbox"], [type="radio"]), textarea {
    margin-top: -1rem;
  }
}

textarea {
  border: 1px solid currentColor;
  border-radius: 10px;
  padding: .75rem;
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-body);
  line-height: var(--font-lineheight-body);
  resize:vertical;
}




.radio,
.checkbox {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0;
  cursor: pointer;
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-small);
  user-select: none;

  &[aria-disabled="true"] {
    pointer-events: none;
    opacity: .5;
  }

  &:hover {
    & input ~ .checkmark {
      opacity: 1;
    }
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: currentColor;

      &:after {
        display: block;
      }
    }
  }

  & .checklabel {
    display: inline-block;
    font-size: var(--font-size-small);
    line-height: var(--font-lineheight-body);
    text-align: left;
  }

  & .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: var(--color-white);
    border: 1px solid currentColor;
    border-radius: 4px;
    transition: opacity .2s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 8px;
      height: 12px;
      border: solid var(--color-blue-dark);
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
    }
  }
}

.radio {
  & .checkmark {
    border-radius: 100%;

    &:after {
      left: 6px;
      top: 6px;
      width: 8px;
      height: 8px;
      border: none;
      background-color: var(--color-white);
      border-radius: 100%;
    }
  }
}
