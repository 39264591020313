@import "../../styles/variables.css";

.nrep-logo {
  width: 31px;
  min-width: 31px;
  height: 40px;
}

/*
.nrep-logo {
  width: 104px;
  min-width: 104px;
  height: 30px;
}
*/

.arrow-left {
  width: 18px;
  min-width: 18px;
  height: 6px;
}

.nav-left-small,
.nav-right-small {
  width: 8px;
  min-width: 8px;
  height: 14px;
}

.nav-left,
.nav-right {
  width: 12px;
  min-width: 12px;
  height: 20px;
}

.nav-down,
.nav-up {
  width: 20px;
  min-width: 20px;
  height: 12px;
}

.info,
.plus {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.checkmark {
  width: 74px;
  min-width: 74px;
  height: 74px;
}

.file {
  width: 22px;
  min-width: 22px;
  height: 28px;
}

.play {
  width: 100px;
  min-width: 100px;
  height: 100px;
}

.pin {
  width: 14px;
  min-width: 14px;
  height: 17px;
}

.house {
  width: 15px;
  min-width: 15px;
  height: 16px;
}
