@import "../../styles/variables.css";

.Platform {
  position: relative;
  padding: calc(3.75 * var(--grid-row)) 0 0 0;
}

.PlatformNoImages {
  composes: Platform;
  padding: 0;
  background-color: var(--color-blue-dark-10) !important;

  .content {
    background-color: transparent !important;
  }
}

.imagesContainer {
  position: relative;
  max-width: 100%;
  overflow: hidden;

  @media (--mq-sm) {
    padding-left: var(--grid-column-half);
    padding-right: var(--grid-column-half);
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    background-color: var(--color-blue-dark-10) !important;
    width: 100%;
    height: 65%;
    top: 35%;

    @media (--mq-sm) {
      height: 35%;
      top: 65%;
    }
  }
}

.images {
  position: relative;
  width: calc(200% + 22px);
  margin-left: calc(-50% - 11px);
  display: grid;
  justify-content: stretch;
  align-items: flex-end;
  column-gap: 22px;
  row-gap: 22px;

  @media (--mq-sm) {
    width: 100%;
    margin-left: 0;
  }
}

.imageWrapper {
  &:nth-of-type(1) {
    grid-column: 1 / span 2;
    grid-row: first-line / 1;
  }

  &:nth-of-type(2) {
    grid-column: 3 / span 2;
    grid-row: first-line / 1;
  }

  &:nth-of-type(3) {
    grid-column: 2 / span 2;
    grid-row: second-line / 3;

    img {
      border-radius: 0;

      @media (--mq-sm) {
        border-radius: var(--border-radius);
      }
    }
  }

  img {
    border-radius: var(--border-radius);
    overflow: hidden;
  }

  @media (--mq-sm) {
    &:nth-of-type(1) {
      grid-column: 1 / span 1;
      grid-row: first-line / 1;
    }

    &:nth-of-type(2) {
      grid-column: 2 / span 1;
      grid-row: first-line / 1;
    }

    &:nth-of-type(3) {
      grid-column: 3 / span 1;
      grid-row: first-line / 1;
    }
  }
}

.content {
  background-color: var(--color-blue-dark-10) !important;
  padding-bottom: var(--grid-row-2);
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: var(--grid-row) 0;
}

.logo {
  max-width: 8rem;
  margin: .5rem 0;
}

.buttonLink {
  margin-top: 4rem;
}
