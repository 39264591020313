@import "../../styles/variables.css";

.PropertyAttribute {
  section {
    &:first-of-type {
      padding-top: var(--padding-section-first-top-xs);

      @media (--mq-sm) {
        padding-top: var(--padding-section-first-top-md);
      }
    }

    &:last-of-type {
      padding-bottom: calc(1.5 * var(--padding-section-first-top-xs));

      @media (--mq-sm) {
        padding-bottom: calc(1.5 * var(--padding-section-first-top-md));
      }
    }
  }
}
