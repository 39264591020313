@import "../../styles/variables.css";

.PropertyHeader {
  padding-top: var(--grid-row);
  margin-top: calc(-1 * var(--grid-row));
  background-color: var(--color-blue-dark-10);
  overflow: hidden;
}

.logoPlatformContainer {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-bottom: 2rem;
  min-width: 30vw;
  min-height: 2rem;

  @media (--mq-sm) {
    min-width: var(--grid-column-2);
    padding-left: 1rem;
    align-self: flex-start;
  }

  figure {
    min-width: var(--grid-column-2);

    @media (--mq-sm) {
      min-width: calc(var(--grid-column-2) - 1rem);
    }
  }
}

.logoPlatform {
  background-position: 100% 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30vw;
  min-width: 30vw;
  height: 3.5rem;
  min-height: 2rem;

  @media (--mq-sm) {
    width: 100%;
    min-width: 100%;
  }
}

.features {
  position: relative;
  background-color: var(--color-white);
  border: var(--border-dark);
  border-radius: var(--border-radius);
  margin: 0 0 var(--grid-row) 0;
  padding: 1.25rem 1rem 1rem 1rem;

  @media (--mq-sm) {
    padding: 2rem;
  }
}

.tags {
  margin-bottom: 2rem;
}

.keyFeaturesMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (--mq-sm) {
    display: none;
    flex-direction: row;
  }
}

.keyFeaturesDesktop {
  composes: keyFeaturesMobile;
  display: none;

  @media (--mq-sm) {
    display: flex;
  }
}

.imageWrapper {
  width: 100%;
  margin-bottom: 1.25rem;

  @media (--mq-sm) {
    flex-basis: 40%;
    margin-right: var(--grid-gutter-2);
  }
}

.image {
  object-fit: cover;
  border-radius: var(--border-radius);
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
}

.description {
  @media (--mq-sm) {
    flex-basis: 60%;
  }
}

.buttonToggle {
  position: absolute;
  transform: translateX(50%);
  top: calc(100% - 4.5rem);
  right: 50%;
  width: 45px;
  min-width: 45px;
  min-height: 45px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 100%;

  @media (--mq-sm) {
    transform: translateX(0);
    top: 1.125rem;
    right: 1.4rem;
  }
}
