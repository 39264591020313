@import "variables.css";

button {
  appearance: none;
  background-color: transparent;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  color: inherit;
  max-width: 100%;
  border: none;
  outline: none;
  cursor: pointer;

  &[disabled],
  &[aria-disabled] {
    pointer-events: none;
    opacity: .5;
  }
}

.button {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-headers);
  text-transform: uppercase;
  letter-spacing: .03em;
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  border: 2px solid var(--color-blue-dark);
  border-radius: 24px;
  padding: 2px 1.5rem 0 1.5rem;
  width: auto;
  height: 48px;
  transition: opacity .2s;

  @media (--mq-md) {
    height: 45px;
  }

  &.inverted {
    background-color: var(--color-white);
    color: var(--color-blue-dark);
    border: 2px solid var(--color-white);
  }

  &.blue {
    background-color: var(--color-blue);
    color: var(--color-white);
    border: 2px solid var(--color-blue);
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid currentColor;
    color: currentColor;
  }

  &.link {
    background-color: transparent;
    border: none;
    color: var(--color-blue);
  }

  &[disabled],
  &[aria-disabled] {
    pointer-events: none;
    opacity: .5;
  }

  svg + span {
    margin-left: .25em;
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;

  & .button {
    margin-bottom: 1rem;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
}
