@import "../../styles/variables.css";

.PropertiesMapFilters {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  height: calc(100% - 5rem);
  filter: drop-shadow(5px 2px 5px rgba(0, 0, 0, 0.1));
  border-top: var(--border-light);
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1rem);
  transition: transform .35s var(--cubic-ease-out), visibility .35s, opacity .35s;

  &:before {
    z-index: -1;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    opacity: .9;
  }

  &:after {
    z-index: -1;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-blue-dark);
    mix-blend-mode: darken;
    opacity: .05;
  }

  @media (--mq-sm) {
    width: 50vw;
  }

  @media (--mq-md) {
    width: 33.3333vw;
  }

  @media (--mq-xl) {
    width: 25vw;
  }
}

.show {
  composes: PropertiesMapFilters;
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.buttonFilter {
  z-index: 1;
  position: absolute;
  padding: 12px 1.25rem 12px calc(var(--grid-column-half) + var(--grid-gutter));
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  transition: background-color .2s;

  @media (--mq-sm) {
    width: 50vw;
  }
  @media (--mq-md) {
    width: 33.3333vw;
  }
  @media (--mq-xl) {
    width: 25vw;
  }

  > * {
    position: relative;
  }
}

.buttonFiltersOpen {
  composes: buttonFilter;
  background-color: var(--color-white-90);

  .buttonBg {
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
    background-color: var(--color-blue-dark-5);
    border-radius: 0;
  }

  .iconBg {
    background-color: var(--color-blue-dark);

    svg {
      transform: rotate(135deg);
      color: var(--color-white);
    }
  }
}

.buttonReset {
  z-index: 1;
  position: absolute;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  text-transform: none;
  align-items: center;
  display: flex;
  top: 1.2rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility .3s, opacity .3s;
  left: 9rem;

  @media (--mq-sm) {
    left: 10rem;
  }
  @media (--mq-xl) {
    left: 11rem;
  }
  @media (--mq-xxl) {
    left: 13rem;
  }
}

.buttonResetShow {
  composes: buttonReset;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.buttonBg {
  z-index: 0;
  position: absolute !important;
  content: '';
  left: 0;
  top: 0;
  transform: translate(.75rem, .75rem);
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: width .2s, height .2s, background-color .2s, border-radius .2s, transform .2s;
}

.numFilters {
  font-weight: var(--font-weight-regular);
  margin-left: .25em;
}

.iconBg {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  transition: background-color .2s;

  svg {
    transform: rotate(0);
    transition: transform .2s, color .2s;
  }
}

.scrollContainer {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.content {
  position: relative;
  padding: 2rem var(--grid-gutter) 5rem calc(var(--grid-column-half) + var(--grid-gutter));
}

.title {
  margin: 0;
}

.buttonClose {
  composes: iconBg;
  position: absolute;
  top: var(--margin-p);
  right: 1rem;
  transform: rotate(45deg);

  @media (--mq-sm) {
    right: 2rem;
  }
}

.divider {
  margin: 0;
}
