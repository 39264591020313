@import "../../styles/variables.css";

.Login {
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  min-height: calc(100vh - 110px);
  padding: var(--grid-row) 0;
}

.LoginHero {
  composes: Login;
  min-height: 100vh;
  padding: calc(var(--grid-row) + 110px) 0 var(--grid-row) 0;
}

.form {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fieldSet {
  border: var(--border);
  padding: 1.25rem 1rem 0 1rem;
  margin: 1.25rem 0 3rem 0;
  border-radius: var(--border-radius);
  width: 100%;
}

.label {
  color: var(--color-label);
  text-transform: uppercase;
  margin-bottom: -.5rem;
}

.inputEmail {
  border: none !important;
  text-align: center;
  font-weight: var(--font-weight-medium) !important;
  margin-bottom: .25rem !important;
}

.checkBox {
  display: inline-block;
}

.termsLink {
  color: var(--color-blue);
  font-weight: var(--font-weight-medium) !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.button {
  display: inline-block;
  min-width: 40%;
  margin-top: 3rem;
  text-transform: uppercase;
}

.text {
  display: flex;
  margin-top: 2rem;
  text-align: left;

  svg {
    margin-right: .5rem;
    color: var(--color-yellow);
  }

  a {
    white-space: nowrap;
  }
}
