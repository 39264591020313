@import "../../styles/variables.css";

.PropertyTabs {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 48px;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: var(--color-blue-dark-10);
}

.list {
  position: relative;
  display: flex;
  justify-content: stretch;
  flex-wrap: nowrap;
  width: max-content;
  min-width: 100%;
  height: 48px;
  list-style-type: none;
  margin: 0;
  padding: 0 calc(var(--grid-column-half) + var(--grid-gutter));
  background-color: var(--color-white);
  border-bottom: var(--border-dark);

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-blue-dark-10);
  }

  @media (--mq-sm) {
    width: auto;
  }

  li {
    flex-grow: 1;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  width: 100%;
  height: 48px;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: var(--font-family-gotham);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray);
  text-decoration: none !important;

  &:hover {
    color: var(--color-gray-dark);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: transparent;
    transition: background-color .2s;
  }
}

.active {
  composes: link;
  color: var(--color-blue-dark);

  &:before {
    background-color: var(--color-blue-dark);
  }
}
