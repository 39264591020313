@import "../../styles/variables.css";

.Logo {
  display: flex;
  width: 100%;
}

.linkLogo {
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (--mq-sm) {
    display: inline-flex;
    justify-content: flex-start;
    width: auto;
  }

  &:hover {
    text-decoration: none;
  }
}

.titleWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (--mq-sm) {
    justify-content: flex-start;
  }
}

.title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .04em;
  text-align: right;
  line-height: 1em;
  margin: 4px 0 0 0;

  @media (--mq-sm) {
    margin: 0 0 0 87px;
  }
}
