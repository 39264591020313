@import "../../styles/variables.css";

.Header {
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  width: 100%;
  height: 90px;
}

.HeaderWithHero {
  composes: Header;
  position: absolute;
  background-color: transparent;
}

.nav {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: var(--grid-row);
}

.linkBack {
  font-size: var(--font-size-small);
  font-family: var(--font-family-gotham);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: var(--color-gray);
  transition: color .2s;

  &:hover {
    color: var(--color-blue-dark);
    text-decoration: none;
  }

  svg {
    margin-right: .5rem;
  }
}
