@import "../../styles/variables.css";

.ModalOnBoarding {
  z-index: var(--z-index-modal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffee;
  pointer-events: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility .35s, opacity .35s;
  will-change: visibility, opacity;
}

.show {
  composes: ModalOnBoarding;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;

  .background {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.bgClose {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-dark);
  opacity: .3;
  cursor: default;
}

.background {
  display: flex;
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  opacity: 0;
  transform: translate(-50%, calc(-50% + 10px));
  transition: transform .35s var(--cubic-ease-out), opacity .35s;
  will-change: transform, opacity;
  transition-delay: .2s;
  width: calc(100% - (2 * var(--grid-gutter)));
  max-height: 90%;

  @media (--mq-sm) {
    width: calc(100% - (4 * var(--grid-column)));
    max-height: 90%;
  }

  @media (--mq-md) {
    width: 60%;
  }

  @media (--mq-lg) {
    width: 50%;
  }

  @media (--mq-xxl) {
    width: 30%;
  }
}

.content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}

.screens {
  display: flex;
  flex-wrap: nowrap;
  transition: transform .4s var(--cubic-ease-in-out);
}

.dots {
  display: flex;
  justify-content: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonOk {
  min-width: 12rem;
  justify-content: center;
  margin: 2rem 0 2rem 0;
}

.buttonSkip {
  min-width: 12rem;
  justify-content: center;
  margin: 0 0 2rem 0;
  color: var(--color-blue);
  text-transform: uppercase;
}

.buttonClose {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 100%;

  svg {
    transform: rotate(45deg);
  }
}
