@import "../../styles/variables.css";

.PropertyKeyFigures {
  width: 100%;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (--mq-sm) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    height: 100%;
  }

  @media (--mq-lg) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.status {
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: flex-start;
  line-height: var(--font-lineheight-headers);

  > span {
    &[class^="dot"] {
      display: flex;
      width: 9px;
      min-width: 9px;
      height: 9px;
      min-height: 9px;
      border-radius: 100%;
      margin: .15rem .5rem 0 0;
    }

    &[class*="Under development"] {
      background-color: var(--color-yellow);
    }
    &[class*="In operation"] {
      background-color: var(--color-green);
    }
    &[class*="Exited"] {
      background-color: var(--color-purple);
    }
  }
}

.statusLabel {
  line-height: var(--font-lineheight-headers);
  color: var(--color-gray-dark);
  font-size: var(--font-size-small);
}
