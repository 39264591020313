@import "../../styles/variables.css";

.PropertyKeyFiguresItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  width: 100%;
  min-height: 71px;
  margin: 0;
  font-family: var(--font-family-gotham);

  &:not(:last-of-type) {
    border-bottom: var(--border-dark);
  }

  @media (--mq-sm) {
    padding: 1rem 1.5rem 1rem 2.5rem;

    &:nth-of-type(1),
    &:nth-of-type(3) {
      border-right: var(--border-dark);
    }

    &:nth-of-type(3) {
      border-bottom: none;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      border-bottom: var(--border-dark);
    }
  }

  @media (--mq-lg) {
    padding: 0 .5rem 0 2.5rem;
    width: 25%;
    min-height: 7rem;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      border-bottom: none;
    }

    &:not(:last-of-type) {
      border-right: var(--border-dark);
    }
  }

  dt {
    text-transform: uppercase;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--font-lineheight-headers);
    white-space: nowrap;
  }

  dd {
    margin: .5rem 0 0 0;
    font-size: var(--font-size-header-md);
    font-weight: var(--font-weight-bold);
  }
}
