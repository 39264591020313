@import "../../styles/variables.css";

.Footer {
  display: flex;
  align-items: center;
  background-color: var(--color-graphite);
  color: var(--color-white);
  min-height: calc(4 * var(--grid-row));
  height: auto;
  padding: var(--grid-row) 0 var(--grid-row-2) 0;

  a {
    white-space: nowrap;
  }
}

.logoWrapper {
  width: 100%;
  margin-bottom: 4rem;
}

.linkDataRoom {
  display: flex;
  color: var(--color-blue);
  margin-bottom: 2rem;

  @media (--mq-sm) {
    margin-top: -2px;
  }
}

.logoAIFMWrapper {
  width: 100%;
  display: flex;

  @media (--mq-sm) {
    justify-content: flex-end;
  }
}

.logoAIFM {
  width: 88px;
  height: 88px;

  @media (--mq-sm) {
    margin-top: -14px;
  }
}
