@import "../../../styles/variables.css";

.OnBoardingScreen {
  width: 100%;
  padding: var(--grid-row) var(--grid-gutter) 0 var(--grid-gutter);

  @media (--mq-md) {
    padding: var(--grid-row) var(--grid-column) 0 var(--grid-column);
  }
}

.figure {
  margin: 0 auto 2rem auto;
  max-width: 80%;

  @media (--mq-md) {
    max-width: 50%;
  }
}
